import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import './index.css';
import axios from 'axios';
import FunnelLP from './pages/FunnelLP';
import ColdTrafficLP1 from './pages/ColdTrafficLP1';
import InvoicePage from './pages/CreateInvoice';
import Call from './pages/Call';
import DQ from './pages/DQ';
import MQL from './pages/MQL';
import Loom from './pages/Loom';
axios.defaults.baseURL = process.env.REACT_APP_DEV_API_BASE_URL;
 
function App() {

  

  return (
    <Router>
        <div className="App">
          <Routes>
            <Route index path='/' element={<ColdTrafficLP1/>}/>
            <Route index path='/free-training' element={<ColdTrafficLP1/>}/>
            <Route index path='/create-invoice' element={<InvoicePage/>}/>
            <Route path='/call' element={<Call/>}/>
            <Route path='/dq' element={<DQ/>}/>
            <Route path='/mql' element={<MQL/>}/>
            <Route path='/booked' element={<Loom/>}/>
            <Route path='*' element={<ColdTrafficLP1/>}/>
          </Routes>
        </div>
    </Router>

  );
}

export default App;