import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, ModalCloseButton, Input, Button, Tooltip } from '@chakra-ui/react';

const InvoicePage = () => {
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [isLoading1, setIsLoading1] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    itemQty: '',
    itemPrice: '',
    dueDate: '',
    projectDuration: '', // New field
  });
  const [loading, setLoading] = useState(false);
  const [invoiceLink, setInvoiceLink] = useState('');

  // Fetch current clients on mount
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get('https://retainlysms-backend.herokuapp.com/api/clients'); // Backend endpoint to get current clients
        setClients(response.data.clients);
        setFilteredClients(response.data.clients);
        setIsLoading1(false);
      } catch (error) {
        console.error('Error fetching clients:', error);
        setIsLoading1(false);
      }
    };
    fetchClients();
  }, []);

  // Live search functionality
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredClients(
      clients.filter(
        (client) =>
          client?.name?.toLowerCase().includes(value) ||
          client?.email?.toLowerCase().includes(value)
      )
    );
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Validate form data
  const isFormValid = () => {
    const { itemQty, itemPrice, dueDate, projectDuration } = formData;
    const isProjectDurationValid =
      projectDuration === '' || (Number(projectDuration) > 0 && Number.isInteger(Number(projectDuration)));
    return (
      itemQty > 0 &&
      itemPrice > 0 &&
      dueDate.trim() !== '' &&
      !isNaN(Date.parse(dueDate)) &&
      isProjectDurationValid
    );
  };

  // Handle invoice creation
  const createInvoice = async () => {
    if (!isFormValid()) {
      alert('Please fill in all fields correctly.');
      return;
    }
    setLoading(true);

    try {
      const response = await axios.post('https://retainlysms-backend.herokuapp.com/api/create-invoice', {
        client: selectedClient,
        itemQty: formData.itemQty,
        itemPrice: formData.itemPrice,
        dueDate: formData.dueDate,
        projectDuration: formData.projectDuration, // Include in request
      });
      setInvoiceLink(response.data.invoiceLink);
    } catch (error) {
      console.error('Error creating invoice:', error);
      alert('Failed to create invoice. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Modal close handler
  const closeModal = () => {
    setModalOpen(false);
    setFormData({ itemQty: '', itemPrice: '', dueDate: '', projectDuration: '' });
    setInvoiceLink('');
  };

  return (
    <div className="flex flex-col items-center p-6">
  <h1 className="text-2xl font-bold mb-4">Create Stripe Invoice Link</h1>
  {isLoading1 ? (
    <h1>Loading...</h1>
  ) : (
    <>
      <div style={{ width: "100%", maxWidth: "800px", margin: "0 auto", marginBottom: "20px" }}>
        <div style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
          <iframe
            src="https://www.loom.com/embed/f0f133dae24c42379d8407cd2be24e31?sid=0bffd28a-8575-4632-a202-8100b9cb5a90"
            frameBorder="0"
            allowFullScreen
            style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
          ></iframe>
        </div>
      </div>

      <Input
        placeholder="Search by name or email"
        value={searchTerm}
        onChange={handleSearch}
        className="mb-4 w-1/2"
      />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
        {filteredClients.map((client) => (
          <div
            key={client.id}
            className="shadow-custom p-4 rounded-lg cursor-pointer hover:shadow-lg"
            onClick={() => {
              setSelectedClient(client);
              setModalOpen(true);
            }}
          >
            <h2 className="text-lg font-bold">{client.name}</h2>
            <p>{client.email}</p>
          </div>
        ))}
      </div>

      <Modal isOpen={modalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Invoice for {selectedClient?.name}</ModalHeader>
          <ModalCloseButton />
          {!invoiceLink && (
            <>
              <ModalBody>
                <div className="mb-4">
                  <label htmlFor="itemQty" className="block font-medium text-gray-700">
                    Item Quantity
                  </label>
                  <Input
                    id="itemQty"
                    name="itemQty"
                    value={formData.itemQty}
                    onChange={handleInputChange}
                    type="number"
                    placeholder="Enter the item quantity"
                    className="mt-1"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="itemPrice" className="block font-medium text-gray-700">
                    Item Price (USD)
                  </label>
                  <Input
                    id="itemPrice"
                    name="itemPrice"
                    value={formData.itemPrice}
                    onChange={handleInputChange}
                    type="number"
                    placeholder="Enter the item price"
                    className="mt-1"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="dueDate" className="block font-medium text-gray-700">
                    Due Date
                  </label>
                  <Input
                    id="dueDate"
                    name="dueDate"
                    value={formData.dueDate}
                    onChange={handleInputChange}
                    type="date"
                    placeholder="Enter the due date"
                    className="mt-1"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="projectDuration" className="block font-medium text-gray-700">
                    Project Duration (Months) <span className="text-gray-500">(Optional)</span>
                  </label>
                  <Input
                    id="projectDuration"
                    name="projectDuration"
                    value={formData.projectDuration}
                    onChange={handleInputChange}
                    type="number"
                    placeholder="Enter project duration in months"
                    className="mt-1"
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={createInvoice}
                  isLoading={loading}
                  disabled={!isFormValid()}
                  colorScheme="blue"
                  className="w-full"
                >
                  Create Invoice Link
                </Button>
              </ModalFooter>
            </>
          )}
          {invoiceLink && (
            <ModalBody className="mt-4 text-center">
              <p className="text-green-600">Invoice Link Created:</p>
              <Tooltip label="Copy Link">
                <Button
                  onClick={() => navigator.clipboard.writeText(invoiceLink)}
                  colorScheme="teal"
                  className="mt-2"
                >
                  Copy Link
                </Button>
              </Tooltip>
              <p className="text-blue-500 mt-2">{invoiceLink}</p>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </>
  )}
</div>

  );
};

export default InvoicePage;
